import React, { useContext, useEffect } from "react";
import { useState } from "react";
import "./article.css";
import { AppContext } from "../../App";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import burger from '../../assets/burger.png'
const Articles = () => {
  const [articles, setArticles] = useState([]);
  const { route, setLoader, imgRoute } = useContext(AppContext);
  const [search, setSearch] = useState("");
  const [art,setArt]=useState("اختر مقالة من جدول المقالات لتطلع عليها")
  const [artTitle,setArtTitle]=useState("اختر مقالة")
  const param =useParams()

  const changeSearch = () => {
    setLoader(true);

    fetch(`${route}/article/search/${search}`)
      .then((res) => res.json())
      .then((data) => {
        setLoader(false);
        if (data.status === "success") {
          setArticles(data.data);
        } else {
          toast.warning(data.msg);
        }
      });
  };
  const chooseArt =(id)=>{
    clickBurger()
    setLoader(true)
    fetch(`${route}/article/show/${id}`)
    .then(res=>res.json())
    .then(data=>{
      setLoader(false)
    if(data.status === "success"){
      setArtTitle(data.data.title)
      setArt(data.data.content)
    }
    })
  }
  useEffect(() => {
    if(param.id){
      chooseArt(param.id)
    }
    
      }, []);
  useEffect(() => {
    fetch(`${route}/article`)
      .then((res) => res.json())
      .then((data) => {
        if (data.data) {
          setArticles(data.data);
          console.log(data.data);
        }
      });

      
  }, []);

  const clickBurger =()=>{
    document.querySelector(".articles .search").classList.toggle("search-show")
  }
 
  return (
    <div className="articles">
      <div className="container">
        <h1>أحدث المقالات القانونية</h1>

    <div className="sub-show" onClick={clickBurger}>
      <img src={burger} alt="" />
      اطلع علي مقالاتنا
    </div>
        <div className="all-art">
          <div className="left">
          
    <h2>{artTitle}</h2>
    <p>{art}</p>
          </div>
          <div className="search">
            <div className="title">ابحث في مقالاتنا </div>
            <div className="srch-btn">
              <input
                placeholder="ابحث هنا"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                type="text"
              />
              <button onClick={changeSearch}>بحث</button>
            </div>
            <div className="arts">
              <h3>أخر المقالات</h3>
              {articles.map((art, index) => {
                return (
                  <div onClick={()=>chooseArt(art.id)} className="article-card" key={index}>
                    <h3 className="title">{art.title}</h3>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Articles;
