import React, { useEffect } from 'react'
import './home.css'
import Landing from '../landing/Landing'
import News from '../news/News'


function Home(props) {

  return (
    <div className="home">
     <Landing />
     <News />
      
    </div>
  )
}

export default Home