import React, { useContext, useEffect, useState } from 'react'
import { PayPalScriptProvider ,PayPalButtons } from '@paypal/react-paypal-js';
import { useParams } from 'react-router-dom';
import { AppContext } from '../../App';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../payPal/pay.css'

const PayModel = () => {
const param =useParams()
const {route,price,login}=useContext(AppContext)




const handleBuy = async ()=>{
  const formData = new FormData();
  formData.append('userId', sessionStorage.getItem("id"));
  formData.append('modelId', param.id);
await fetch(`${route}/model/grantAccess`, {
  method :"PUT",
          headers:{
            'Authorization': `Bearer ${sessionStorage.getItem("token")}` ,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            userId : sessionStorage.getItem("id") ,
            modelId : param.id
          }),
        })
 .then(res=>res.json())
 .then(data=>{
 console.log(data)
})}

// window.paypal.Buttons({
//   createOrder: function(data, actions) {
//     return actions.order.create({
//       purchase_units: [{
//         amount: {
//           value: '600.00'
//         }
//       }]
//     });
//   },
//   onApprove: function(data, actions) {
//     return actions.order.capture().then(function(details) {
//       console.log(details);
//       toast.success("su")
//     });
//   }
// }).render('#pay-pal');




  return (
   <div className="pay-pal">
    <div className="container">
    <PayPalScriptProvider className="pay-container" options={{"client-id":"AWYLyoE1COcQjuIEP3zApBrAdrRW82SwgnifPbBD46bauDzbkvBLrgPdfZQP2s0RpxamhaDYa1evXA9a"}}>
      <PayPalButtons 
    
        createOrder={(data, actions) => {
          return actions.order
              .create({
                  purchase_units: [
                      {
                          amount: {
                              // currency_code: currency,
                              value: `${param.price}.00`,
                          },
                      },
                  ],
              })
        
      }}
      onApprove={(data,actions)=>{
        return actions.order.capture().then(function (details) {
         if(details.status == "COMPLETED"){
            handleBuy()
          toast.success(" تم الدفع" )

         } 
         
        })
      }}
   
      />
      
    </PayPalScriptProvider>

    <div id="pay-pal"></div>
    <div className="price">price : {param.price}$</div>
    </div>
   </div>
  )
}

export default PayModel
