import React from 'react'
import './service.css'

const Services = () => {
  return (
 <div className="service">
    <div className="container">
        <h1>خدماتنا</h1>
        <h2>سند القانوني يوفر لك الخدمات الاتية</h2>
        <ul>
            <li>صياغة اللوائح والمذكرات والاعتراضات امام كافة الجهات القضائية وشبة القضائية</li>
            <li>صياغة ومراجعة كافة أنواع العقود</li>
            <li>تقديم كافة الأبحاث والدراسات القانونية</li>
            <li>تقديم كافة الاستشارات القانونية لمعالجة الإشكالات القانونية سواء كانت إشكالات قانونية بسيطة او متوسطة او مُعقدة</li>
            <li>إمكانية عقد اجتماع مع أي من السادة المستشارين لدى "سند القانوني" لتقديم الدعم القانوني اللازم</li>
            <li>نقدم مكتبة قانونية تحتوي على العديد من الصيغ القانونية من عقود ولوائح وخلاف ذلك، ونقوم بتحديث المكتبة بصياغات جديدة بشكل يومي</li>
            <li>نُقدم خدمة توفير السند القانوني، عيك فقط عرض الحالة علينا ونقوم بتزويدك بالأسانيد القانونية والشرعية المؤيدة للموضوع</li>
          
        </ul>
    </div>
 </div>
  )
}

export default Services
