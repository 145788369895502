
import React, { useContext, useEffect } from 'react'
import { useState } from 'react';
import { AppContext } from '../../App';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './news.css'
import { Link } from 'react-router-dom';
const News = () => {
    const [articles ,setArticles]=useState([])
    const {route ,setLoader ,imgRoute}=useContext(AppContext)

    useEffect(()=>{
        fetch(`${route}/article`)
        .then(res=>res.json())
        .then(data=>{
          if(data.data){
            setArticles(data.data)
            console.log(data.data)
          }
        })
      },[])
  return (
   <div className="news">
    <div className="container">
        <h2>احدث المقالات</h2>
        <div className="arts">
        {articles.map((art,index)=>{
            if(index < 5){

                return(
           
                  <div class="card" key={index}>
    <div class="content">
      <p class="heading">{art.title}
      </p>
      <Link to={`/articles/${art.id}`} class="btn">اقرأ المزيد</Link>
    </div>
  </div>
                )
            }
            })}
        </div>
    </div>
   </div>
  )
}

export default News